<template>
  <section class="hero is-fullheight login-page">
    <div class="hero-body">
      <div class="container">
        <div v-if="step > 1" @click="step--" class="button is-darkened is-dark back-btn">&larr; Back</div>
        <!-- <div class="buttons are-small">
          <div @click="prevStep()" class="button is-small">Prev</div>
          <div @click="nextStep()" class="button">Next</div>
        </div> -->
        <div :class="['step-' + step, 'register-' + isRegister, {'has-error': error}]" class="form-wrap login">
          <img class="logo" src="/swf-logo-dark.svg" style="margin:0;">
          <hr>
          <transition name="fade">
            <template v-if="isRegister">
              <div v-if="step === 1" class="register-form" style="position: absolute;">
                <h2 class="title is-4 m-t-20">Where will you use this?</h2>
                <div class="use-cases">
                  <div @click="selectPersona('school')" class="use-button">
                    <img src="/streamline/school-bus.png">
                    <div class="desc">
                      <p class="title is-5">School</p>
                      <p class="subtitle is-6">Higher-ed, K12, or other education</p>
                    </div>
                  </div>
                  <div @click="selectPersona('work')" class="use-button">
                    <img src="/streamline/office-building.png">
                    <div class="desc">
                      <p class="title is-5">Work</p>
                      <p class="subtitle is-6">Meetings, events, training, and more</p>
                    </div>
                  </div>
                  <div @click="selectPersona('personal')" class="use-button">
                    <img src="/streamline/movie-seat.png">
                    <div class="desc">
                      <p class="title is-5">Personal Use</p>
                      <p class="subtitle is-6">Gatherings with friends &amp; family</p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="step === 2 && persona !== 'personal'" class="register-form register-step-2">
                <article v-if="error" class="message error-message is-danger">
                  <section class="message-body">{{ error }}</section>
                </article>
                <b-field :label="isEdu ? 'School or educational institution' : 'Company name'" class="m-t-20">
                  <b-input v-model="orgName" :name="isEdu ? 'school' : 'work'" type="text" value="host" />
                </b-field>

                <b-field v-if="isEdu" label="What is your role?">
                  <b-dropdown v-model="orgRole" name="role">
                    <template #trigger="{ active }">
                      <b-button
                        :icon-right="active ? 'caret-up' : 'caret-down'"
                        :icon-pack="fas"
                        :label="orgRole"
                        type="is-gray"
                      />
                    </template>
                    <b-dropdown-item v-for="role in eduRoles" :key="'org-' + role" :value="role">{{ role }}</b-dropdown-item>
                  </b-dropdown>
                </b-field>

                <b-field v-else class="m-t-20" label="What is your role?">
                  <b-input v-model="orgRole" name="role" type="text" value="host" />
                </b-field>

                <b-field label="Audience size" class="m-t-20">
                  <b-slider v-model="groupSize" :step="10" :value="51" :min="10" :max="groupSizeMax" :custom-formatter="groupSizeIndicator" :tooltip="false" indicator />
                </b-field>
                <br>
                <div @click="goToRegister()" class="m-t-30 is-primary button is-fullwidth is-medium">
                  Next &rarr;
                </div>
                <p class="tos m-t-10">Step 2 of 3</p>
              </div>

              <div v-if="step === 2 && persona === 'personal'" class="register-form register-step-2">
                <article v-if="error" class="message error-message is-danger">
                  <section class="message-body">{{ error }}</section>
                </article>

                <b-field class="m-t-20" label="What type of event are you hosting  ?">
                  <b-input v-model="eventName" name="event-type" type="text" value="host" />
                </b-field>

                <b-field class="m-t-20" label="How often do you host events?">
                  <b-input v-model="eventFrequency" name="event-frequency" type="text" value="host" />
                </b-field>

                <b-field label="Audience size" class="m-t-20">
                  <b-slider v-model="groupSize" :step="10" :value="51" :min="10" :max="groupSizeMax" :custom-formatter="groupSizeIndicator" :tooltip="false" indicator />
                </b-field>
                <br>
                <div @click="goToRegister()" class="m-t-30 is-primary button is-fullwidth is-medium">
                  Next &rarr;
                </div>
                <p class="subtitle is-5">Step two of three</p>
              </div>

              <form v-if="step === 3" @keyup.enter="loginOrRegister" class="register-form">
                <template v-if="initializing">
                  <b-loading :is-full-page="false" v-model="initializing" />
                </template>

                <!-- REGISTER FORM -->
                <div v-if="isRegister && !initializing">
                  <article v-if="error" class="message error-message is-danger">
                    <section class="message-body">{{ error }}</section>
                  </article>

                  <b-field label="Full Name">
                    <b-input v-model="name" name="name" type="name" value="name" />
                  </b-field>

                  <div class="field">
                    <label class="label">{{ emailLabel }}</label>
                    <p v-if="persona === 'school'" class="subtitle is-7" style="margin:-.75em 0 .5em"> Use your official email to be eligible for discounts</p>
                    <b-input v-model="email" @blur="checkEmailSpelling()" name="email" type="email" value="john@email.com" />
                    <span v-if="suggestion" @click="setSuggestedEmail()" class="suggestion">do you mean <u>{{ suggestion }}</u>?</span>
                  </div>

                  <b-field label="Password">
                    <b-input v-model="password" name="password" type="password" password-reveal />
                  </b-field>

                  <div
                    @click="loginOrRegister"
                    :disabled="loading"
                    :class="{'is-loading': loading}"
                    name="submit"
                    type="submit"
                    class="m-t-30 is-primary button is-fullwidth is-medium"
                  >
                    Create Your Account
                  </div>

                  <p class="tos m-t-10">By signing up, you agree to our <n-link to="/c/terms">Terms of Service</n-link> and <n-link to="/c/privacy-policy">Privacy Policy</n-link></p>
                </div>
              </form>
            </template>

            <!-- LOGIN FORM -->
            <form v-if="!isRegister" @keyup.enter="loginOrRegister" class="register-form">
              <template v-if="initializing">
                <b-loading :is-full-page="false" v-model="initializing" />
              </template>

              <div v-if="!initializing">
                <article v-if="error" class="message error-message is-danger">
                  <section class="message-body">{{ error }}</section>
                </article>

                <b-field label="Email">
                  <b-input v-model="email" name="email" type="email" value="john@email.com" />
                </b-field>

                <b-field label="Password">
                  <b-input v-model="password" name="password" type="password" password-reveal />
                </b-field>

                <div
                  @click="loginOrRegister"
                  :disabled="loading"
                  :class="{'is-loading': loading}"
                  name="submit"
                  type="submit"
                  class="m-t-30 is-primary button is-fullwidth is-medium"
                >
                  Login
                </div>

                <p class="tos m-t-10">Not working? <n-link to="/reset-password" class="">Reset your password</n-link></p>
              </div>
            </form>
          </transition>

          <div v-if="validPendingInvite" class="login-quote">
            <img class="quote-image" src="https://assets-global.website-files.com/62ba01ba5ddce99465927db5/630d0988955082e4ef087afd_Join%20on%20Your%20Phone2sm-p-500.png">
            <h3 class="title is-3 is-white">
              You have a pending team invitation &mdash; login or create an account to accept.
            </h3>
          </div>

          <div v-else class="login-quote">
            <img class="quote-image" src="https://uploads-ssl.webflow.com/608c168b336d57443c79da71/60dcb84cf50aba5bb434fb71_Screen_phone%20minimal-p-500.png">
            <i class="fas fa-quote-left" />
            <h3 class="title is-3 is-white">So fun, easy, and engaging. We get the best feedback from our partcipants!</h3>

            <div class="quote-author">
              <img src="/gesche.jpg">
              <p class="author-name">
                <span class="title is-5">Gesche Haas</span>
                <span class="subtitle">@geschehaas</span>
              </p>
              <i class="fab fa-twitter social-media-icon" />
            </div>
          </div>
        </div>

        <div v-if="step < 3" class="meta-links">
          <p v-if="!isRegister" class="m-t-15 has-text-centered has-text-white"><n-link :to="{path: 'register', query: $route.query}">New here? <u>Create your account</u></n-link></p>
          <p v-if="isRegister" class="m-t-10 has-text-centered has-text-white"><n-link :to="{path: 'login', query: $route.query}">Have an account? <u>Login here</u></n-link></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable quotes */

import emailSpellChecker from '@zootools/email-spell-checker'
import jwtDecode from 'jwt-decode'
import { getNhostAuthUrl } from '../common/nhost-url'
import { validateEmail } from '~/common/functions.js'
import { updateUserMetadata } from '~/queries/updateUserProfile'

export default {
  name: 'LoginPage',
  auth: false,
  head () {
    return {
      title: 'Login'
    }
  },
  data () {
    return {
      password: '',
      name: '',
      email: '',
      suggestion: null,
      error: '',
      persona: '',
      loading: false,
      initializing: true,
      triedIntercomUpdate: 0,
      step: 1,
      totalSteps: 3,
      progress: 0,
      orgName: '',
      orgRole: 'Select role...',
      eventName: '',
      eventFrequency: '',
      groupSizeChanged: false,
      groupSizeNumber: 151,
      groupSizeLimits: {
        school: 200,
        work: 500,
        personal: 500
      },
      eduRoles: [
        'Teacher',
        'School Admin',
        'District Admin',
        'Department Head',
        'IT/Tech Specialist',
        'Non-Profit Educator',
        'Other'
      ],
      localStorageInvite: ''
    }
  },
  computed: {
    groupSize: {
      set (val) {
        // use the modulo operator to check if the number ends in zero, and if not make it end in zero
        // this is used validate that the slider has been modified before submitting the form
        if (val % 10 !== 0) {
          this.groupSizeNumber = val - (val % 10)
        }

        // if (this.persona === 'school' && val > 190) {
        //   this.groupSizeLimits.school = 500
        // }

        this.groupSizeNumber = val
        this.$nextTick(() => {
          this.groupSizeNumber = val
        })
      },
      get () {
        return this.groupSizeNumber
      }
    },
    validPendingInvite () {
      const regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')
      const invite = this.$route.query.invite || this.localStorageInvite
      return regex.test(invite) ? invite : false
    },
    groupSizeMax () {
      return this.groupSizeLimits[this.persona]
    },
    isRegister () {
      return this.$route.path === '/register' || this.$route.path === '/register/'
    },
    isEdu () {
      return this.persona === 'school'
    },
    emailLabel () {
      switch (this.persona) {
        case 'school' : return 'School Email'
        case 'work' : return 'Work Email'
        default : return 'Email'
      }
    },
    nhostMetadata () {
      return {
        userType: this.persona,
        orgRole: this.orgRole,
        orgName: this.orgName,
        groupSize: this.groupSize
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    // console.log('beforeRouteEnter: ', { to, from })
    next()
  },
  mounted () {
    if (this.isRegister && localStorage.startReg) {
      const data = JSON.parse(atob(localStorage.startReg))
      localStorage.removeItem('startReg')

      // include to make this update sorta work with webflow full form data
      if (data['Register-Email']) { this.email = data['Register-Email'] }
      if (data['Register-Name']) { this.email = data['Register-Name'] }
      if (data['Register-Pw']) { this.email = data['Register-Pw'] }
      return
    }

    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }

    if (this.$route.query.invite) {
      this.localStorageInvite = this.$route.query.invite
      localStorage.setItem('swf-team-invite', this.$route.query.invite)
    } else if (localStorage.getItem('swf-team-invite')) {
      this.localStorageInvite = localStorage.getItem('swf-team-invite')
    }

    if (this.$auth.user?.id && this.validPendingInvite) {
      this.log('user is logged in, accepting and recdirecting')
      this.goToNextPage()
      this.initializing = false
      return
    } else {
      this.log('user is not logged in, prompting to login or register')
    }

    if (this.isRegister) {
      let firstPage
      if (localStorage && localStorage.getItem('firstLanding')) {
        firstPage = JSON.parse(localStorage.getItem('firstLanding'))
      }

      const data = {}
      if (this.$route.query.startDeck) { data.start_deck_id = this.$route.query.startDeck }
      if (firstPage?.url?.length > 3) { data.$set_once = { first_page: firstPage.url } }
      this.posthogRegisterData = data

      this.posthogCapture('register:start', data)
      this.$posthog.startSessionRecording()
    }

    this.initializing = false
  },
  methods: {
    checkEmailSpelling () {
      const suggestion = emailSpellChecker.run({ email: this.email })
      if (suggestion) {
        this.suggestion = suggestion.full
      } else {
        this.suggestion = null
      }
    },
    setSuggestedEmail () {
      this.email = this.suggestion
      this.suggestion = null
    },
    nextStep (num = 1) {
      this.step = this.step + num
      setTimeout(() => {
        this.progress = ((this.step - 1) / this.totalSteps) * 100
      }, 300)
    },
    prevStep () {
      if (this.step === 0) { return }
      this.step--
      setTimeout(() => {
        this.progress = ((this.step - 1) / this.totalSteps) * 100
      }, 300)
    },
    clearTeamInvite () {
      window.localStorage.removeItem('swf-team-invite')
      this.$router.replace({ query: {} })
    },
    validatePersonal () {
      return true
    },
    validateBusiness () {
      if (!this.orgName || this.orgName.length < 3) {
        this.error = 'Please enter your company name'
        return false
      }
      if (!this.orgRole || this.orgRole.length < 3 || this.orgRole === 'Select role...') {
        this.error = 'Please select what role you fill at your company'
        return false
      }
      return true
    },
    validateEdu () {
      if (!this.orgName || this.orgName.length < 3) {
        this.error = 'Please enter your educational institution'
        return false
      }
      if (!this.orgRole || this.orgRole.length < 3 || this.orgRole === 'Select role...') {
        this.error = 'Please select what role you fill at your school'
        return false
      }
      return true
    },
    goToRegister () {
      let isValid
      if (this.persona === 'personal') {
        isValid = this.validatePersonal()
      } else if (this.persona === 'school') {
        isValid = this.validateEdu()
      } else {
        isValid = this.validateBusiness()
      }

      if (!isValid) { return }

      if (this.groupSizeNumber === 151 || this.groupSizeNumber === 51) {
        this.error = 'Please select your audience size'
        return
      }

      const companyData = {
        user_company: this.orgName,
        user_role: this.orgRole,
        user_group_size: this.groupSizeNumber
      }
      this.posthogCapture('register:user_data_add', { $set_once: companyData })

      this.nextStep()
      this.error = null
    },
    selectPersona (persona) {
      this.persona = persona

      if (persona === 'work') { this.orgRole = '' }
      if (persona === 'school' && this.orgRole === '') { this.orgRole = 'Select role...' }

      if (persona === 'school') {
        this.groupSizeNumber = 51
      }

      this.posthogCapture('register:user_type_select', { $set_once: { user_type: persona } })
      this.nextStep()
    },
    goToNextPage () {
      if (this.validPendingInvite) {
        this.$router.push({ path: '/account/team', query: this.$route.query })
        return
      }
      this.$router.push({ path: '/decks', query: this.$route.query })
    },
    groupSizeIndicator (num) {
      if (num === 151 || num === 51) { return '---' }
      if (num === 10) { return '<10' }
      if (num === this.groupSizeMax) { return this.groupSizeMax + '+' }
      return num
    },
    async loginOrRegister () {
      if (this.isRegister) {
        await this.register()
        this.trackEvent('New User Register')
      } else {
        await this.login()
        this.trackEvent('User Login')
      }
    },
    async login (email, password, cancelRedirect) {
      // console.log('trying login')
      this.error = ''
      this.loading = true

      email = email || this.email
      password = password || this.password
      if (!email || !password) {
        this.error = 'Please insert an email and password'
        this.loading = false
        return
      }

      try {
        await this.$auth.loginWith('local', {
          data: {
            email,
            password
          }
        })

        this.$buefy.toast.open({ message: 'Success!', type: 'is-primary' })

        this.posthogCapture('user:login')

        // reset apollo headers and close ws connection to force new auth headers
        const client = this.$apolloProvider.defaultClient.wsClient
        const token = this.$auth.getAccessToken()
        client.connectionParams = () => {
          return {
            headers: { Authorization: token }
          }
        }
        client.close(false)

        // set user for auth module
        this.setUser()

        if (cancelRedirect !== false) { // this is set false to add stages to registration
          this.$nextTick(() => {
            this.goToNextPage()
          })
        }
      } catch (e) {
        this.throwError(e, 'toast')
      }

      this.loading = false
    },
    setUser () {
      // console.log('setting user after login')
      const token = this.$auth.getAccessToken()
      let claims = jwtDecode(token)
      claims = claims['https://hasura.io/jwt/claims']

      this.log('token claims: ', claims)

      const user = {
        id: claims['x-hasura-user-id'],
        default_role: claims['x-hasura-default-role'],
        teamIds: claims['x-hasura-Team-Ids']
      }
      this.$auth.setUser(user)
    },
    async register () {
      // console.log('trying registration')
      this.error = ''
      this.loading = true

      if (!this.email || !this.password) {
        this.error = 'Please insert an email and password'
        this.loading = false
        return
      }

      if (!this.name) {
        this.error = 'Please enter your name'
        this.loading = false
        return
      }

      const postData = {
        email: this.email,
        password: this.password,
        options: {
          displayName: this.name,
          metadata: this.nhostMetadata
        }
      }

      this.log('registering user with data: ', postData)

      const endpoint = getNhostAuthUrl() + '/signup/email-password'
      try {
        await this.$axios.post(endpoint, postData)

        const data = { $set: { user_name: this.name } }
        if (this.$route.query.startDeck) { data.start_deck_id = this.$route.query.startDeck }
        this.posthogCapture('register:complete', data)
        this.$store.commit('clearLocalData')

        await this.login(postData.email, postData.password, false)
        this.goToNextPage()
        this.$buefy.toast.open({ message: `Welcome to SlidesWith! 🙌`, type: 'is-primary' })
        this.beginVerifyEmail()
      } catch (e) {
        this.posthogCapture('register:error', { error: e.message })
        this.throwError(e, 'toast')
      }

      this.loading = false
    },
    async beginVerifyEmail () {
      this.log('validating user email: ', this.email)
      try {
        const validation = await validateEmail(this.email)
        const meta = { ...this.nhostMetadata, validation }
        const userId = this.$auth.user.id

        this.log('email validity checked and saved: ', validation)
        meta.confirmationEmailSent = await this.sendConfirmationEmail(userId, validation)
        this.log('confirmation email sent? ' + meta.confirmationEmailSent)

        await this.$apollo.mutate({
          mutation: updateUserMetadata,
          variables: {
            userId,
            metadata: meta
          }
        })
      } catch (e) {
        this.log(e.message)
      }
    },
    async sendConfirmationEmail (userId, validation) {
      this.log('sending confirmation email to: ', this.email, userId, validation)
      if (validation.isValid === false) { return false } // don't bother trying to confirm an invalid email

      // get first name from full name
      const name = this.name.split(' ')[0]
      const email = {
        to: this.email,
        templateId: 39502212,
        variables: {
          firstName: name,
          base64User: btoa(userId)
        },
        tag: 'confirm-user-email'
      }

      this.log('sending user confirmation email')

      try {
        const resp = await this.$axios.post(process.env.SITE_URL + '/api/send-email-template', email)
        this.log('user confirmation sent: ', resp)
        if (resp.status === 200) {
          this.$store.commit('confirmationEmailSent', true)
          this.posthogCapture('register:confirm_email_sent', { $set: { confirm_email_sent: true } })
          return true
        }
      } catch (e) {
        this.log(e.message)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>

.suggestion {
  display: inline-block;
  background-color: rgb(243, 230, 153);
  color: #444;
  padding: 6px 14px;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
}

.team-notice {
  position: absolute;
  margin: 0 auto;
  left: 0; right:0;
  text-align: center;
  top: 10px;
  max-width: 600px;
  transform: scale(.9);
}
.back-btn {
  position: fixed;
  top: 3em;
  left: 2em // - 6.5em;
}

.login-page {
  hr {
    margin-top: 1.1em;
    margin-bottom: 1em;
  }
}
.use-cases {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .use-button {
    padding: .75em .75em;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
    cursor: pointer;

    img {
      width: 5em;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }
}
.logo {
  width:100%;
  text-align:center;
  height:50px;
  margin: 20px auto 20px;
}

.form-wrap.login {
  box-shadow: 0px 10px 55px rgba(0,0,0,.1);
  min-height: 450px;
  min-width: 400px;
  position: relative;
  // overflow: hidden;
  transition: min-height .3s ease;
}

.form-wrap.login.register-true {
  &.step-1 {
    min-height: 650px;
  }

  &.step-2 {
    min-height: 570px;
  }

  &.step-2.has-error {
    min-height: 630px;
  }

  &.step-3 {
    min-height: 565px;
  }

  &.step-3.has-error {
    min-height: 650px;
  }
}

.register-true .register-form,
.use-case-wrap {
  position: absolute;
  width: calc(100% - 100px);
  height: calc(100% - 80px);
}

.login-page {
  // background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
  // background-image: linear-gradient( -39.6deg,  rgba(253,199,141,1) 11.3%, rgba(249,143,253,1) 100.2% );
  // background-image: linear-gradient(-39.6deg, #f3d6ff 11.3%, #daeefc 100.2%);
  // background-image: linear-gradient(-19.6deg, #ccb9e2 1.3%, #d6e6ff 100.2%);
  background-color: #bca6d5;
  background-color: #8268a1;
}

.has-text-white{
  a {
    font-size: .9em;
    font-weight: bold;
  }
}

.tos {
  font-size: .8em;
  text-align: center;
}

a.has-text-dark {
  &:hover {
    text-decoration: underline;
  }
}

.login-quote {
  position: absolute;
  right: -330px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  width: 270px;

  & > .title {
    font-size: 1.45em;
    line-height: 1.1em;
  }

  .title, .subtitle {
    color: white;
  }

  .quote-image {
    width: 270px;
    margin-bottom: 10px;
    margin-left: -26px;
  }
  .quote-author {
    display: flex;
    color: #666;
    align-items: flex-start;
    width: 100%;
    margin-left: -2px;

    img{
      width: 50px;
      height: 50px;
      border-radius: 30px;
      margin-top: 0px;
    }

    .author-name {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      margin-top: 2px;

      .title {
        margin: 0;
        font-size: 1.15rem;
        font-weight: bold;
        margin-bottom: -1px;
      }
      .subtitle {
        margin: 0;
        font-size: .85em;
      }
    }
  }
  .fab {
    align-self: flex-start;
    flex-grow:1;
    text-align: right;
    // margin-left: 5px;
    margin-right: 20px;
    color: #52a2e9;
    margin-top: 5px;
  }
  .fas {
    margin-left: -27px;
    margin-bottom: -20px;
    opacity: .5;
  }
}

@media screen and (min-width: 860px) {
  .login-quote {
    display: flex;
  }

  .meta-links, .form-wrap.login {
    transform: translateX(-100px);
  }
}
</style>
